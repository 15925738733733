import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { useLazyGetVehiclesQuery } from '../../../features/vehicle/VehicleSlice';
import { useAddVehicleToBookingMutation } from '../../../features/vehicle/VehicleSlice';
import SearchableSelect from '../../Search/SearchableSelect';
import { useSnackbar } from '../../SneckBarProvider';
import { useLazyIsVehicleAssignedForDateQuery } from '../../../features/rides/rides';
import WarningDialog from '../../Dialog/WarningDialog';
import ComfirmationDialog from '../../Dialog/ConfirmationDialog';
import moment from 'moment';

export default function AddVehicleForm({
  onSuccess,
  reservations,
  rideProperties,
}) {
  const { t } = useTranslation();
  const showSnacbar = useSnackbar();
  const [getVehicles] = useLazyGetVehiclesQuery();
  const showSnackbar = useSnackbar();
  const [addVehicleToBooking, { isLoading, isSuccess, isError, error }] =
    useAddVehicleToBookingMutation();

  console.log(rideProperties);

  const [IsVehicleAssignedForDate, { data: isVehicleAssigned }] =
    useLazyIsVehicleAssignedForDateQuery();

  const [vehicles, setVehicles] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [openComf, setOpenComf] = useState(false);

  const fetchVehicles = async () => {
    const response = await getVehicles();
    if (response.data) {
      setVehicles(response.data);
    }
  };

  useEffect(() => {
    fetchVehicles();
  }, [getVehicles]);

  const handleSubmit = async () => {
    // event.preventDefault();
    try {
      const addVehicleToBookingObj = {
        vehicle: selectedItem,
        reservations,
        rideProperties,
      };
      await addVehicleToBooking(addVehicleToBookingObj).unwrap();
      if (onSuccess) {
        onSuccess();
        showSnackbar(
          'Vehicle successfully assigned to reservations',
          'success'
        );
      }
      handleCloseComfDialog();
    } catch (err) {
      console.error('Failed to add vehicle to booking: ', err);
    }
  };

  const onVehicleChange = (event) => {
    console.log(event);
    if (event) {
      IsVehicleAssignedForDate({
        departure: rideProperties.departure,
        vehicleId: event.id,
        routeId: rideProperties.routeId,
        direction: rideProperties.rideDirection,
      });
    }
    setSelectedItem(event);
  };

  useEffect(() => {
    if (isVehicleAssigned?.length > 0) {
      showSnacbar(`vehicleAssignedRide`, 'warning');
    }
  }, [isVehicleAssigned]);

  const generateMessage = () => {
    var message = '';
    message += `${t('generateMessage.forDate')} <b>${moment(rideProperties.departure).format('DD-MM-YYYY')}</b>, ${t('generateMessage.vehicle')} <b>${selectedItem.registrationPlates}</b> ${t('generateMessage.isAlreadyAssigned')}.<br />`;
    // message+="Voznje: <br />"
    isVehicleAssigned.forEach((assignedVehicles) => {
      message += `${t('generateMessage.vehicleHasAScheduledRide')} <b>${assignedVehicles.route}</b>.<br />`;
    });
    message += '<br />';
    console.log(message);
    return message;
  };

  const handleCloseComfDialog = () => {
    setOpenComf(false);
  };
  const openConfDialg = () => {
    setOpenComf(true);
  };

  return (
    <Box>
      <SearchableSelect
        setSelected={onVehicleChange}
        items={vehicles}
        itemName={'Vehicle'}
      ></SearchableSelect>
      <Button
        sx={{ marginTop: '10px' }}
        type="submit"
        className="create-button"
        color="primary"
        disabled={selectedItem === null}
        onClick={openConfDialg}
      >
        {isLoading ? t('customDialog.adding') : t('customDialog.addVehicle')}
      </Button>
      {isError && (
        <p>
          {t('customDialog.errorAdding')}: {error.toString()}
        </p>
      )}
      {openComf && (
        <>
          {isVehicleAssigned?.length > 0 ? (
            <WarningDialog
              open={openComf}
              onClose={handleCloseComfDialog}
              onNo={handleCloseComfDialog}
              onYes={handleSubmit}
              dialogContent={generateMessage()}
            />
          ) : (
            <ComfirmationDialog
              open={openComf}
              onClose={handleCloseComfDialog}
              onNo={handleCloseComfDialog}
              onYes={handleSubmit}
              dialogContent={'chooseVehicle'}
            />
          )}
        </>
      )}
    </Box>
  );
}
