import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Container,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as NotiifyIcon } from '../../Icons/notification-icon.svg';

import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { viewUserById } from '../../services/UserService';
import { logOut } from '../../features/auth/authSlice';
import { ReactComponent as Settings } from '../../Icons/settings-01.svg';
import { ReactComponent as Profile } from '../../Icons/user-01.svg';
import { ReactComponent as LogoutIcon } from '../../Icons/logouticon.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import './Appbar.css';
import { useSignalRConection } from '../../store/context/SignalRContext';
import { mainLinks } from './consts/mainLinks';
import { sideLinks } from './consts/sideLinks';
import { CustomNavLink } from './CustomNavLink';
import { CustomMenuItem } from './CustomMenuItem';
import { allowedRoles } from './consts/allowedRoles';
import DrawerBar from './Drawer';
import { useRecords } from '../../store/context/SMSRecordContext';
// import i18n from 'i18next';

const ResponsiveAppBar = ({}) => {
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElSubNav, setAnchorElSubNav] = useState(null);
  const [anchorElUserNav, setAnchorElUserNav] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [languageState, setLanguageState] = React.useState('srpski');
  const { t, i18n } = useTranslation();
  const isLoggedIn = localStorage.getItem('token');
  const userRole = localStorage.getItem('roles');
  const userId = localStorage.getItem('id');
  const { discconect } = useSignalRConection();
  const { getNumberOfNotifications } = useRecords();
  const dispatch = useDispatch();

  const handleOpenNavSubMenu = (event) => {
    handleCloseNavMenu();
    handleCloseNavSubMenu();
    setAnchorElSubNav(event.currentTarget);
  };

  const handleCloseNavSubMenu = () => {
    setAnchorElSubNav(null);
  };
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  async function ViewUserById(id) {
    try {
      const user = (await viewUserById(id)).data;
      setUser(user);
      // setOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (userId != null) {
      ViewUserById(userId);
    }
  }, [userId]);

  const hangleChangeLanguage = () => {
    const currentLanguage = i18n.language;

    if (languageState === 'srpski') {
      setLanguageState('english');
    } else {
      setLanguageState('srpski');
    }

    const newLanguage = i18n.language === 'en' ? 'rs' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const handleOpenNavMenu = (event) => {
    handleCloseNavSubMenu();
    handleCloseProfileMenu();

    setAnchorElNav(event.currentTarget);
  };
  const handleCloseProfileMenu = (event) => {
    setAnchorElUserNav(null);
  };
  const handleOpenUserMenu = (event) => {
    handleCloseNavSubMenu();
    handleCloseProfileMenu();

    setAnchorElUserNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleLogoutNavigate = async () => {
    discconect(userId);

    dispatch(logOut());
    setUser(null);
    navigate('/');
  };
  const handleCloseMenu = () => {
    handleCloseNavMenu();
    handleCloseNavSubMenu();
    handleCloseProfileMenu();
  };

  const handleOpenInstruction = () => {
    const url =
      'https://docs.google.com/document/d/1x-igp4-bRfQGSTvJQv547vHwn8NRPiIM/edit#heading=h.gjdgxs';
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const isSelected = (path) => location.pathname === path;

  return isLoggedIn ? (
    <AppBar
      sx={{
        backgroundColor: 'white',
        color: 'black',
        zIndex: '90',
        alignItems: 'center',
        boxShadow: 'none !important',
        borderBottom: '1px solid #EAECF0',
      }}
    >
      <Container
        sx={{
          maxWidth: '1280px !important',
          padding: '0px 32px 0px 32px !important',
        }}
      >
        <Toolbar sx={{ padding: '0px !important' }}>
          <NavLink className="header-icon-container" to="/CreateBooking" />

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
            }}
          >
            <IconButton
              size="large"
              aria-label="open navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              className="header-menu-container"
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {mainLinks.map((link) => (
                <CustomMenuItem
                  key={link.key}
                  className="menu-link-container"
                  link={link}
                  onClick={handleCloseMenu}
                />
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            <NavLink className="header-icon-containe" to="/CreateBooking" />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: 'none',
                md: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
              },
            }}
          >
            {mainLinks.map((link) => (
              <CustomNavLink
                key={link.key}
                className="header-link-container"
                link={link}
              />
            ))}
            {userRole === 'Admin' && (
              <>
                <div className="menu-settings">
                  <Menu
                    className="header-menu-container"
                    id="basic-menu"
                    anchorEl={anchorElSubNav}
                    open={Boolean(anchorElSubNav)}
                    onClose={handleCloseNavSubMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    {sideLinks.map((link) => (
                      <CustomMenuItem
                        key={link.key}
                        className="menu-link-container"
                        link={link}
                        onClick={handleCloseMenu}
                      />
                    ))}
                  </Menu>
                </div>
              </>
            )}
          </Box>
          <Box
            sx={{
              color: 'red',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <span
              className="header-lenguage-span"
              onClick={hangleChangeLanguage}
            >
              {i18n.language === 'rs' ? 'SRB' : 'ENG'}
            </span>
            {userRole === 'Admin' && (
              <Settings
                style={{ cursor: 'pointer' }}
                onClick={handleOpenNavSubMenu}
                className="settings"
              ></Settings>
            )}
            {(userRole === 'Admin' || userRole == 'Operater') && (
              <div style={{ height: '40px' }} onClick={toggleDrawer(true)}>
                {console.log('num of not:' + getNumberOfNotifications())}
                {getNumberOfNotifications() > 0 && (
                  <span className="app-bar-notification-number">
                    {/* || getNumberOfNotifications() === '99+' // if logic in getNumberOfNotifications change */}
                    {getNumberOfNotifications() > 99
                      ? '99+'
                      : getNumberOfNotifications()}
                  </span>
                )}
                <NotiifyIcon
                  style={{ cursor: 'pointer' }}
                  className="settings"
                ></NotiifyIcon>
              </div>
            )}
            <Profile
              style={{ cursor: 'pointer' }}
              onClick={handleOpenUserMenu}
              className="settings"
            ></Profile>
            {allowedRoles.includes(userRole) && (
              <HelpOutlineIcon
                style={{ cursor: 'pointer' }}
                onClick={handleOpenInstruction}
                className="settings setting-open-drive"
              />
            )}
            {open == true && (
              <DrawerBar open={open} onClose={toggleDrawer(false)}></DrawerBar>
            )}
          </Box>
        </Toolbar>
      </Container>
      <Menu
        className="header-menu-container"
        id="user-menu"
        anchorEl={anchorElUserNav}
        open={Boolean(anchorElUserNav)}
        onClose={handleCloseProfileMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {user && (
          <div className="header-menu-user-info-container">
            <span className="header-name-user-style">
              {user.firstName} {user.lastName}
            </span>
            <span className="header-mail-user-style">{user.userName}</span>
          </div>
        )}
        {/* </div> */}
        {allowedRoles.includes(userRole) && (
          <MenuItem onClick={handleCloseMenu}>
            <NavLink
              className="menu-link-container"
              to={`/UserProfile/${userId}`}
            >
              <Profile />
              {t('appbar.myAccount')}
            </NavLink>
          </MenuItem>
        )}
        <MenuItem onClick={handleCloseMenu}>
          <span onClick={handleLogoutNavigate} className="menu-link-container">
            <LogoutIcon /> {t('appbar.logout')}
          </span>
        </MenuItem>
      </Menu>
    </AppBar>
  ) : (
    <></>
  );
};

export default ResponsiveAppBar;
