import { MenuItem } from "@mui/material";
import { CustomNavLink } from "./CustomNavLink";


export const CustomMenuItem = ({ className, onClick, link }) => {
  const userRole = localStorage.getItem('roles');

  if (link.roles.includes(userRole)) {
    return (
      <MenuItem onClick={onClick}>
        <CustomNavLink key={link.key} className={className} link={link} />
      </MenuItem>
    );
  }
  return null;
};
