import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  geocodeAddress,
  geocodeByPlaceId,
} from '../../utils/googleMapsHelper/geocode';
import { useSnackbar } from '../SneckBarProvider';
import { checkGeocodedAddres } from '../../utils/googleMapsHelper/geocode';
import { debounce, TextField } from '@mui/material';
import { useLazyGetCustomLocationPredictionsQuery } from '../../features/customlocations/customLocationSlice';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ReactComponent as Pin } from '../../Icons/marker-pin-04.svg';

// import useDebounce from "../../utils/timeoutHelper/useDebounde";
import './InputAutocomplete.css';
import { useGridDensity } from '@mui/x-data-grid/internals';
import { Foundation } from '@mui/icons-material';
import GoogleMapComponent from './GoogleMap';
import GoogleMapDialog from './GoogleMapDialog';
const libs = ['places', 'routes', 'drawing', 'geometry'];

const InputAutoComplete = (props) => {
  const {
    placeholder,
    labelText,
    onLocationChange,
    reesetChange,
    locationForUpdate,
    label,
    useForFiltering,
    customStyle,
    hasIcon,
    fillAutoCompleteFromMap,
    disabled,
    onChange,
    locationSetter,
    onChangeLocation = () => {},
    title,
    onManualType,
    openForEdit,
    pastReservation,
    isCreatorNull,
    isForReservationCreateOrEdit = false,
  } = props;
  const [geocoder, setGeocoder] = useState(null);
  const [location, setLocation] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isFocused, setFocused] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [autoCompleteValue, setAutocompleteValue] = useState('');
  const [customLocationsPredictions, setCustomLocationPredictions] = useState(
    []
  );
  // console.log(isCreatorNull)
  const [open, setOpen] = useState(null);

  const [focusedIndex, setFocusedIndex] = useState(-1);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_PTB_MAP_APIKEY,
    libraries: libs,
    language: 'sr-Latn',
  });
  const showSnackbar = useSnackbar();
  const [
    getCustomLocationPredictions,
    { data: customLocations, error: predictionsError, isLoading },
  ] = useLazyGetCustomLocationPredictionsQuery();
  const debounceTimeout = useRef(null);
  const ulRef = useRef(null);
  const inputRef = useRef(null);
  useEffect(() => {
    if (isLoaded) {
      const autocompleteInstance =
        new window.google.maps.places.AutocompleteService();
      setGeocoder(new window.google.maps.Geocoder());
      setAutocomplete(autocompleteInstance);
      setLocationForUpdate();
    }
  }, [isLoaded]);

  // useEffect(() => {
  //   if (isLoaded) {
  //     // const autocompleteInstance =
  //     //   new window.google.maps.places.AutocompleteService();
  //     // setGeocoder(new window.google.maps.Geocoder());
  //     // setAutocomplete(autocompleteInstance);
  //     setLocationForUpdate();
  //   }
  // }, [isLoaded]);
  useEffect(() => {
    // console.log(fillAutoCompleteFromMap);
    if (
      fillAutoCompleteFromMap != undefined &&
      fillAutoCompleteFromMap.lat != '' &&
      fillAutoCompleteFromMap.lng != ''
    ) {
      console.log(fillAutoCompleteFromMap);
      if (!fillAutoCompleteFromMap.location) {
        setAutocompleteValue(getAutocompleteText(fillAutoCompleteFromMap));
      }
      if (locationSetter == true) {
        setLocation(fillAutoCompleteFromMap);
      }
      // if (pom === true) {
      //   console.log("rqwerwqerqewrew");

      //   setLocation(fillAutoCompleteFromMap);
      // }
    }
  }, [fillAutoCompleteFromMap]);

  const setLocationForUpdate = () => {
    // console.log(locationForUpdate);
    if (
      locationForUpdate != undefined &&
      locationForUpdate.lat != '' &&
      locationForUpdate.lng != ''
    ) {
      console.log(locationForUpdate);

      setAutocompleteValue(getAutocompleteText(locationForUpdate));
      setLocation(locationForUpdate);
    }
  };

  const getAutocompleteText = (location) => {
    console.log(location);
    var titleString = '';
    if (title != null) {
      return title;
    } else {
      if (location.street !== 'Unnamed Road' && location.street!="") {
        titleString = titleString + `${location.street}`;
      }
      if (location.number !== 'NN' && location.number!="") {
        titleString = titleString + ` ${location.number}`;
      }
      if (location.city !== 'Undefined city') {
        if (titleString != '' && location.city!="") {
          titleString = titleString + `, ${location.city}, `;
        } else {
          titleString = titleString + `${location.city}, `;
        }
      }
    }
    titleString = titleString + `${location.country}`;
    return titleString;
  };

  const handleChange = (event) => {
    if (event.target.value == '') {
      console.log(event.target.value);
      setFocusedIndex(-1);
      setLocation(null);
    }
    setAutocompleteValue(event.target.value);
    getAutocompletePredictions(event.target.value);
    getCustomLocationPredictionsOnInputChange(event.target.value);
    if (onChange !== undefined) {
      onChange(event);
    }
    onChangeLocation(event.target.value);
    if (isForReservationCreateOrEdit) {
      onManualType(true);
    }
  };

  const getCustomLocationPredictionsOnInputChange = (value) => {
    if (customLocationsPredictions.length != 0) {
      setCustomLocationPredictions([]);
    }
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      if (value.trim().length > 0) {
        getCustomLocationPredictions(value);
      }
    }, 700);
  };

  const debouncedGetPredictions=useCallback(
    debounce((inputValue)=>{
      if (autocomplete && inputValue.trim().length > 0) {
        autocomplete.getPlacePredictions(
          { input: inputValue, types: ['establishment', 'geocode'] },
          (predictions, status) => {
            console.log(predictions);
            if (status === 'OK') {
              setPredictions(predictions);
            } else {
              setPredictions([]);
            }
          }
        );
      } else {
        setPredictions([]);
      }
    },700),[autocomplete]
  );

  const getAutocompletePredictions = (inputValue) => {
    debouncedGetPredictions(inputValue)
  };

  const handleSetLocation = (res) => {
    setLocation(res);
    setPredictions([]);
    setCustomLocationPredictions([]);
  };

  const handleGeoLocationCheck = (res) => {
    const checkResult = checkGeocodedAddres(res);
    if (checkResult === true || useForFiltering) {
      handleSetLocation(res);
    } else {
      setAutocompleteValue('');
      if (location !== null) {
        showSnackbar(checkResult, 'error');
      }
    }
  };

  const getGeocodedAdr = (placeId) => {
    geocodeByPlaceId(placeId, geocoder).then((res) => {
      handleGeoLocationCheck(res);
      // return res;
    });
  };
  const geoCodeByLatLng = (location) => {
    geocodeAddress(location, geocoder).then((res) => {
      handleGeoLocationCheck(res);
      // return res;
    });
  };

  const handleOnGoogleApiPredictionClick = (prediction) => {
    setAutocompleteValue(prediction.description);
    getGeocodedAdr(prediction.place_id);

    // console.log(checkResult);
  };
  const handleOnCustomLocationsPredictionClick = (prediction) => {
    setAutocompleteValue(`${prediction.title}, ${prediction.location.city}`);
    handleSetLocation(prediction.location);
  };

  const handlePredictionClick = (prediction) => {
    console.log(prediction);
    if (prediction != undefined) {
      if (prediction.id != undefined) {
        handleOnCustomLocationsPredictionClick(prediction);
      } else {
        console.log(prediction);

        handleOnGoogleApiPredictionClick(prediction);
      }
    }
  };

  useEffect(() => {
    if (customLocations) {
      setCustomLocationPredictions(customLocations);
    }
  }, [customLocations]);

  const handleKeyDown = (e) => {
    // console.log(predictions.length);
    if (predictions || customLocations) {
      console.log(focusedIndex);
      if (e.key === 'ArrowDown') {
        // console.log(predictions.length);
        if (focusedIndex == -1) {
          if (customLocations != undefined) {
            if (customLocations.length > 0) {
              setFocusedIndex(predictions.length);
            } else {
              setFocusedIndex(0);
            }
          }
        } else {
          setFocusedIndex(
            (prevIndex) =>
              (prevIndex + 1) %
              (predictions.length + customLocationsPredictions.length)
          );
        }
      } else if (e.key === 'ArrowUp') {
        setFocusedIndex((prevIndex) => {
          if (prevIndex <= 0) {
            return predictions.length + customLocationsPredictions.length - 1; // Wrap to the last item
          } else {
            return prevIndex - 1;
          }
        });
      } else if (e.key === 'Enter') {
        if (focusedIndex > -1) {
          if (focusedIndex < predictions.length) {
            console.log('rweewrwerwe');
            handlePredictionClick(predictions[focusedIndex]);
          } else {
            handlePredictionClick(
              customLocationsPredictions[focusedIndex - predictions.length]
            );
          }
        }
      }
    }
  };
  useEffect(() => {
    // console.log(reesetChange);
    setCustomLocationPredictions([]);
    setPredictions([]);
    setLocation(null);
    setAutocompleteValue('');
    setLocationForUpdate();
  }, [reesetChange]);

  useEffect(() => {
    if (location != null) {
      console.log(location);

      onLocationChange(location);
    }
  }, [location]);

  const handleFoucsed = () => {
    // Clear predictions or perform cleanup when input loses focus
    setFocused(true);
  };
  const handleBlur = () => {
    // // Clear predictions or perform cleanup when input loses focus
    // setTimeout(() => {
    //   setFocused(false);
    //   setFocusedIndex(-1);
    // }, 300);
  };

  const onLeftClick = () => {
    console.log('rewrweqrewq');
  };
  const handleRightClick = (event) => {
    // handleChange(event)
    onManualType(true);
    console.log(event);
    geoCodeByLatLng(event.latLng);
  };

  const mapOpeningCondition = () => {
    if (autoCompleteValue.length > 0 && (location || fillAutoCompleteFromMap))
      return true;
    else return false;
  };

  return (
    <>
      {isLoaded && (
        <>
          <div
            // className="custom-text-field22"
            className="input-autoctomplete-google-main-container"
            style={{
              // width: "100%",
              // display: "flex",
              flexDirection: 'column',
              // height: "100%",
              position: 'relative',
              minWidth: '150px',

              flex: '1',
              //   position:"relative"
            }}
          >
            {label && <label className="custom-label">{labelText}</label>}
            {/* <div style={{position:"relative",display:"inline-block"}}> */}
            <input
              type="text"
              name="googleAutoComplete"
              style={{
                ...customStyle,
                // height: "100%",
                flex: 1,
                position: 'relative',
                paddingRight: hasIcon ? '40px' : '8px',
              }}
              placeholder={placeholder}
              // sx={{

              // }}
              autoComplete="off"
              value={autoCompleteValue}
              label={label ? undefined : labelText}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={handleFoucsed}
              onBlur={handleBlur}
              disabled={disabled}
            />
            {hasIcon && (
              <Pin
                onClick={() => {
                  if (
                    (disabled === false || disabled === undefined) &&
                    isCreatorNull == false
                  ) {
                    if (mapOpeningCondition()) {
                      setOpen(true);
                    }
                  }
                }}
                style={{
                  transform: !label ? 'translateY(-50%) translateX(-10px)' : '',
                }}
                className={
                  !disabled && isCreatorNull == false && mapOpeningCondition()
                    ? `autocomolete-icon-container-selected`
                    : 'autocomolete-icon-container'
                }
              />
            )}
            {(predictions.length > 0 ||
              customLocationsPredictions.length > 0) &&
              isFocused && (
                <ul ref={ulRef} className="input-autocomplete-predictions">
                  {customLocationsPredictions &&
                    customLocationsPredictions.map((prediction, index) => (
                      <li
                        key={prediction.id}
                        className={
                          index + predictions.length === focusedIndex
                            ? 'custom-focused'
                            : 'custom-focuseddd'
                        }
                        onClick={() => handlePredictionClick(prediction)}
                      >
                        <LocationOnIcon fontSize="small" />
                        {prediction.title}, {prediction.location.city}
                      </li>
                    ))}
                  {predictions.map((prediction, index) => (
                    <li
                      // className="text-area-with-auto-complete-li"
                      className={
                        index === focusedIndex
                          ? 'focused'
                          : 'not-custom-autocomplete-location'
                      }
                      key={prediction.place_id}
                      onClick={() => handlePredictionClick(prediction)}
                    >
                      <LocationOnIcon fontSize="small" />

                      {prediction.description}
                    </li>
                  ))}
                </ul>
              )}
          </div>
          <>
            {open == true &&
              (location != undefined ||
                fillAutoCompleteFromMap != undefined) && (
                <GoogleMapDialog
                  open={open}
                  location={location || fillAutoCompleteFromMap}
                  onRightClick={handleRightClick}
                  onClose={() => {
                    setOpen(false);
                  }}
                />
              )}
          </>
        </>
      )}
    </>
  );
};

export default InputAutoComplete;
