import { useTranslation } from "react-i18next";
import './style/Rides.css';

export const NoResultFound = ({ count }) => {
  const { t } = useTranslation();
  console.log(count)
  return (
    count == 0 && ( 
      <div className="no-result-found-main-container">
        {t("rides.noResultFound")}
      </div>
    )
  );
};
