import React, { useEffect, useState } from "react";
import "./AddUser.css";

import {
  useAddUserMutation,
  useRegisterInvitationMutation,
  useRegisterMutation,
} from "../../../features/auth/authApiSlice";
import { useSnackbar } from "../../SneckBarProvider";
import PasswordTextField from "../../CustomTextFields/PasswordTextField";
import NameTextField from "../../CustomTextFields/NameTextField";
import UserNameTextField from "../../CustomTextFields/UserNameTextField";
import PhoneTextField from "../../CustomTextFields/PhoneTextField";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useNavigate } from "react-router-dom";
import ButtonValidate from "../../Button/ButtonValidate";
import { formControlStyles } from "../../../customStyles/customStyles";
import EmailTextField from "../../CustomTextFields/EmailTextField";

import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import { roles } from "../../../config";
import RoleFormControl from "../../CustoFormControl/RoleFormControl";
import { useTranslation } from "react-i18next";
import { useGetSubagentsQuery } from "../../../features/subagents/SubagentSlice";
import CustomDropDown from "../../CustomTextFields/CustomDropDown";
import FilledButton from "../../Button/FilledButton";

const AddUserForm = (props) => {
  const { t } = useTranslation();

  const { onClose,onAddUser } = props;
  const [register] = useAddUserMutation();
  const navigate = useNavigate();
  const [selectedSubagentId, setSelectedSubagentId] = useState('');
  const { data: subagents } = useGetSubagentsQuery();
  const [roleName, setRoleName] = useState('');

  const showSnackbar = useSnackbar();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    role: roles[0],
  });
  const [fieldValidities, setFieldValidities] = useState({
    // firstName: false,
    // lastName: false,
    userName: false,
    password: false,
    confirmPassword: false,
    email: false,
    phoneNumber:true
  });

  const handleChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    console.log("NAMEE", name);
    console.log("VALUEE", value);
    setRoleName(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData);
  };

  const handleChangeSubagent = (e) => {
    setSelectedSubagentId(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      subagentId: e.target.value
    }));
  }
  const handlePhoneNumberChange = (phone) => {
    console.log(phone);
    console.log(formData);
    setFormData((prevData) => ({
      ...prevData,
      phoneNumber: phone, // Update the phone number directly
    }));
  };
  const handleFieldValidityChange = (fieldName, isValid) => {
    console.log(fieldName)
    console.log(isValid)
    setFieldValidities((prevValidities) => ({
      ...prevValidities,
      [fieldName]: isValid,
    }));
    // console.log(fieldValidities)
  };

  const handleAddUser = async () => {
    console.log("Fsdafasdfasdf");
    try {
      const response = await register(formData).unwrap();
      showSnackbar(response?.message, "success");
      onAddUser();
      onClose();
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };

  const handlePhoneValidationChange=(isValid)=>{
    console.log(isValid)
    if(formData.role==="Driver" && !isValid){
      handleFieldValidityChange("phoneNumber",false)
      return
    }
    handleFieldValidityChange("phoneNumber",true)
    return
  } 


  useEffect(()=>{
    console.log("teletel",fieldValidities.phoneNumber)
  },[fieldValidities.phoneNumber])

  return (
    <>
      <div className="add-user-form-main-container">
        <div className="add-user-form-twofields-container">
          <div className="add-user-form-onefield-cotaniner">
            <NameTextField
              label="First Name"
              name="firstName"
              nameValue={formData.firstName}
              onNameChange={handleChange}
              // onValidityChange={handleFieldValidityChange}
            />
          </div>
          <div className="add-user-form-onefield-cotaniner">
            <NameTextField
              label="Last Name"
              name="lastName"
              nameValue={formData.lastName}
              onNameChange={handleChange}
              // onValidityChange={handleFieldValidityChange}
            />
          </div>
        </div>
        <UserNameTextField
          label="User Name"
          name="userName"
          userName={formData.username}
          onNameChange={handleChange}
          onValidityChange={handleFieldValidityChange}
        />
        <EmailTextField
          label="Email"
          name="email"
          email={formData.email}
          onEmailChange={handleChange}
          onValidityChange={handleFieldValidityChange}
        />
        {/* <div className="add-user-form-twofields-container">
          <div className="add-user-form-onefield-cotaniner"> */}
        <div className="add-user-form-phone-roles-container">
          <PhoneTextField
            name="phoneNumber"
            value={formData.phoneNumber}
            onPhoneChange={handlePhoneNumberChange}
            useValidationProp={formData.role==="Driver"}
            onValidPhoneNumberChange={handlePhoneValidationChange}
          />
        </div>
        {/* <div className="add-user-form-onefield-cotaniner add-user-form-phone-roles-container"> */}
        {/* <RoleFormControl value={formData.role} onChange={handleChange} /> */}
        <CustomDropDown
            name="role"
            id="role"
            value={formData.role}
            onChange={handleChange}
            label={t('users.filterRole')}
            options={roles}
          />
        {/* {formData.role === 'Subagent' &&
        <FormControl fullWidth
            sx={formControlStyles}>
            <InputLabel id="subagent-select-label">Select Subagent</InputLabel>
            <Select
                labelId="subagent-select-label"
                color="secondary"
                name="role"
                value={selectedSubagentId}
                onChange={handleChangeSubagent}
                label="Select Subagent"
            >
                {subagents && subagents.map((subagent) => (
                    <MenuItem key={subagent.id} value={subagent.id}>
                        {subagent.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>} */}
        {/* </div> */}
        {/* </div> */}
        <div className="add-user-form-twofields-container">
          <div className="add-user-form-onefield-cotaniner">
            <PasswordTextField
              name="password"
              label="Password"
              onPasswordChange={handleChange}
              onValidityChange={handleFieldValidityChange}
              password={formData.password}
            />
          </div>

          <div className="add-user-form-onefield-cotaniner">
            <PasswordTextField
              name="confirmPassword"
              label={"Confirm password"}
              onPasswordChange={handleChange}
              onValidityChange={handleFieldValidityChange}
              password={formData.confirmPassword}
              comperePasswords={formData.password}
            />
          </div>
        </div>
        <FilledButton
          onClick={handleAddUser}
          isValid={fieldValidities.userName && fieldValidities.confirmPassword  && fieldValidities.password && fieldValidities.phoneNumber}
        >
          <SaveOutlinedIcon /> {t(`main.save`)}
        </FilledButton>
      </div>
    </>
  );
};

export default AddUserForm;
