import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
  useDisableBookingMutation,
  useEnableBookingMutation,
  useGetBookingsQuery,
} from '../../../features/bookings/bookingsApiSlice';
import { Button, Card, DialogActions, Tooltip } from '@mui/material';
import AddBookingForm from '../AddBooking/AddBookingForm';
import EditIcon from '@mui/icons-material/Edit';
import NoLuggageIcon from '@mui/icons-material/NoLuggage';
import UpdateBookingForm from '../UpdateBooking/UpdateBookingForm';
import CustomDialog from '../../Dialog/CustomDialog';
import InfoIcon from '@mui/icons-material/Info';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate } from 'react-router-dom';
import {
  useCreateExcelExportMutation,
  useCreatePdfExportMutation,
} from '../../../features/export/exportApiSlice';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import BookingsFilters from './BookingsFilters';
import { useSnackbar } from '../../SneckBarProvider';
import './ShowAllBookingsComponent.css';
import CustomButton from '../../Button/Button';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import UsePagination from '../../Pagginator/Paginator';
import { ClearIcon } from '@mui/x-date-pickers';
import CheckIcon from '@mui/icons-material/Check';
import {handleCreateDownloadLink} from "../../../utils/download-blob/handleCreateDownloadLink";

const ShowAllBookingsComponent = () => {
  const { t, i18n } = useTranslation();

  const columns = [
    { id: 'extend', label: '', minWidth: 10 },
    { id: 'departure', label: t('booking-list.departure'), minWidth: 170 },
    { id: 'start', label: t('booking-list.startDisplay'), minWidth: 170 },
    { id: 'arrival', label: t('booking-list.arrival'), minWidth: 170 },
    { id: 'end', label: t('booking-list.endDisplay'), minWidth: 170 },
    { id: 'isRoundTrip', label: t('booking-list.roundTrip'), minWidth: 100 },
    {
      id: 'isEveningDeparture',
      label: t('booking-list.eveningDeparture'),
      minWidth: 100,
    },
    {
      id: 'status',
      label: 'Status',
      // minWidth: 100,
    },
    // { id: 'more-details', label: t('booking-list.more'), minWidth: 50 },
  ];

  const extendColumns = [
    { id: 'creatorName', label: t('booking-list.creatorName'), minWidth: 170 },
    {
      id: 'reservationsCount',
      label: t('booking-list.reservationCount'),
      minWidth: 100,
    },
    //{ id: 'isActive', label: t('booking-list.status'), minWidth: 100 },
    { id: 'isAvailable', label: t('booking-list.availability'), minWidth: 100 },
    // { id: 'edit', label: t('main.edit'), minWidth: 50 },
    {
      id: 'enable-disable',
      label: t('booking-list.enableDisable'),
      minWidth: 50,
    },
  ];

  const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem('token');
  const userRole = localStorage.getItem('roles');
  const creatorId = localStorage.getItem('id');

  const [initialFilters, setInitialFilters] = useState({
    dateFrom: null,
    dateTo: null,
    code: '',
    startCityName: '',
    endCityName: '',
    startCountryName: '',
    endCountryName: '',
    creatorID: userRole == 'Dispatcher' ? creatorId : '',
    creatorName: '',
    pageSize: 10,
    pageNumber: 1,
    bookingType:
      userRole == 'Admin' ? 'all' : userRole == 'Dispatcher' ? 'all' : '',
    status: true,
  });
  //const showSnackbar = useSnackbar();
  const showSnackbar = useSnackbar();

  const [page, setPage] = useState(0); //first page
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [addOpen, setAddOpen] = useState(false);
  const [disableOpen, setDisableOpen] = useState(false);
  const [enableOpen, setEnableOpen] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [editBookingObj, setEditBookingObj] = useState({});
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [resetState, setResetPaginator] = useState(false);

  const [expandedRow, setExpandedRow] = useState(null);

  const { data, error, isLoading, refetch } =
    useGetBookingsQuery(initialFilters);

  const [disableBooking] = useDisableBookingMutation();

  const [enableBooking] = useEnableBookingMutation();

  const [
    downloadExcelFile,
    {
      data: dataExcelArray,
      error: errorExportExcel,
      isLoading: isLoadingExportExcel,
    },
  ] = useCreateExcelExportMutation();
  const [
    downloadPdfFile /*,{ data: dataPdfArray, error: errorPdfExcel, isLoading: isLoadingExportPdf }*/,
  ] = useCreatePdfExportMutation();

  const toggleExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  //
  const handleChangePage = (newPage) => {
    // console.log(event)
    setPage(newPage);
    //console.log('changed to page: ' + newPage)
    setInitialFilters({
      ...initialFilters,
      pageNumber: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const rpp = parseInt(event.target.value, 10);
    setRowsPerPage(rpp);
    setPage(0);

    setInitialFilters({
      ...initialFilters,
      pageNumber: 0,
      pageSize: rpp,
    });
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = async () => {
    setAddOpen(false);
    refetch();
  };

  const handleDisableOpen = (flag, bookingId) => {
    setSelectedBookingId(bookingId);

    if (flag === 'disable') {
      setDisableOpen(true);
    } else {
      setEnableOpen(true);
    }
  };

  const handleDisableClose = (flag) => {
    if (flag === 'disable-close') {
      setDisableOpen(false);
    } else {
      setEnableOpen(false);
    }
    refetch();
  };

  const handleDisableBooking = async (flag, bookingId) => {
    if (flag === 'disable') {
      try {
        const response = await disableBooking(bookingId).unwrap();
        console.log(response);
        showSnackbar(response?.message, 'success');

        handleDisableClose('disable-close');
      } catch (error) {
        console.error('Error disabling booking:', error);
        showSnackbar(error?.data?.Message, 'error');
      }
    } else {
      try {
        const response = await enableBooking(bookingId).unwrap();
        showSnackbar(response?.message, 'success');

        handleDisableClose('enable-close');
      } catch (error) {
        console.error('Error enabling booking:', error);
        showSnackbar(error?.data?.Message, 'error');
      }
    }
  };

  const handleEditOpen = (booking) => {
    console.log('edit booking obj: ' + JSON.stringify(booking));
    setEditBookingObj(booking);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    refetch();
  };



  const handleOnExcelDownload = async () => {
    const languagePrefix = i18n.language == 'en' ? 'en' : 'rs';

    try {
      //const { data: excelBlob } = await downloadExcelFile(data);
      const response = await downloadExcelFile({
        bookings: data,
        lang: languagePrefix,
      });

      handleCreateDownloadLink(response.data, 'excel', 'rides'); //excelBlob;
    } catch (error) {
      console.error('Error while downloading Excel file:', error);
    }
  };

  const handleOnPdfDownload = async () => {
    try {
      const { data: pdfBlob } = await downloadPdfFile(data);
      handleCreateDownloadLink(pdfBlob, 'pdf','rides');
    } catch (error) {
      console.error('Error while downloading Excel file:', error);
    }
  };

  const onMoreDetails = (routeId, reservationId, bookingId) => {
    navigate(`/ReservationDetails/${bookingId}/${routeId}`, {
      replace: true,
    });
  };

  const handleFilterBooking = (data) => {
    setInitialFilters(data);
    setResetPaginator(!resetState);
  };

  /*const formatDate = (isoString) => {
      const date = new Date(isoString);
      const formattedDate = date.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
      });
      const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
      });
      return `${formattedDate} - ${formattedTime}`;
  };*/

  useEffect(() => {
    console.log('id', creatorId);
    //refetch(); // beacuse get method have param from state it will refetch automaticly
  }, [data]);

  if (error) {
    return (
      <>
        <h3>Try to get bookings with some disabled route stop</h3>
      </>
    );
  }

  if (isLoadingExportExcel) return <div>Loading export...</div>;

  return (
    <div className="main-bookings-container">
      <div className="content-bookings-container">
        {isLoading ? (
          <h2>Loading...</h2>
        ) : (
          <>
            <CustomDialog
              onOpen={disableOpen}
              onClose={() => handleDisableClose('disable-close')}
              contentTitle={t('booking-list.enableDisable')}
              content={t('booking-list.disableConfirmation')}
            >
              <DialogActions>
                <CustomButton
                  onClick={() =>
                    handleDisableBooking('disable', selectedBookingId)
                  }
                >
                  {t('main.yes')}
                </CustomButton>
                <CustomButton
                  onClick={() => handleDisableClose('disable-close')}
                >
                  {t('main.no')}
                </CustomButton>
              </DialogActions>
            </CustomDialog>

            <CustomDialog
              onOpen={enableOpen}
              onClose={() => handleDisableClose('enable-close')}
              contentTitle={t('booking-list.enableDisable')}
              content={t('booking-list.enableConfirmation')}
            >
              <DialogActions>
                <CustomButton
                  onClick={() =>
                    handleDisableBooking('enable', selectedBookingId)
                  }
                >
                  {t('main.yes')}
                </CustomButton>
                <CustomButton
                  onClick={() => handleDisableClose('enable-close')}
                >
                  {t('main.no')}
                </CustomButton>
              </DialogActions>
            </CustomDialog>

            <CustomDialog
              onOpen={editOpen}
              onClose={handleEditClose}
              contentTitle="editRide"
            >
              <UpdateBookingForm booking={editBookingObj}></UpdateBookingForm>
            </CustomDialog>

            <div className="bookings-header">
              {/* <h2>{t('booking-list.rides')}</h2> */}
              <span className="page-header-container">
                {t('booking-list.rides')}
              </span>
              {/* <CustomButton type="add" onClick={handleAddOpen}>
              {t('booking-list.newRide')}
            </CustomButton> */}
              <CustomDialog
                onOpen={addOpen}
                onClose={handleAddClose}
                disableBackdropClick={true}
                contentTitle="addRide"
              >
                <AddBookingForm onClose={handleAddClose}></AddBookingForm>
              </CustomDialog>
            </div>

            <BookingsFilters
              filters={initialFilters}
              onReceiveFilteredBooking={handleFilterBooking}
              role={userRole}
            />
            <>
              <Card className="rides-page-table-container">
                <TableContainer>
                  {' '}
                  {/*sx={{ maxHeight: 640 }} or component={Paper}*/}
                  <Table aria-label="collapsible table">
                    <TableHead style={{ backgroundColor: 'ButtonShadow' }}>
                      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        {columns.map((column, index) => (
                          <TableCell
                            key={index}
                            align={column.align}
                            sx={{ fontWeight: 'bold' }}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {data.bookings.map((row, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                          {/* Main row */}
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={rowIndex}
                          >
                            {columns.map((column, columnIndex) => {
                              let value = null;
                              switch (column.id) {
                                case 'extend':
                                  value =
                                    (console.log(row),
                                    (
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => toggleExpand(rowIndex)}
                                      >
                                        {expandedRow === rowIndex ? (
                                          <ExpandLessIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )}
                                      </IconButton>
                                    ));
                                  break;
                                case 'bookingCode':
                                  value = row['bookingCode'];
                                  break;
                                case 'start':
                                  if (row['direction'] == true) {
                                    value =
                                      row['startCountryName'] +
                                      ' (' +
                                      row['startCityName'] +
                                      ')';
                                  } else {
                                    value =
                                      row['endCountryName'] +
                                      ' (' +
                                      row['endCityName'] +
                                      ')';
                                  }
                                  break;
                                case 'end':
                                  if (row['direction'] == true) {
                                    value =
                                      row['endCountryName'] +
                                      ' (' +
                                      row['endCityName'] +
                                      ')';
                                  } else {
                                    value =
                                      row['startCountryName'] +
                                      ' (' +
                                      row['startCityName'] +
                                      ')';
                                  }
                                  break;
                                case 'departure':
                                  //value = formatDate(row['departure']);
                                  value = dayjs(row['departure']).format(
                                    'DD/MM/YYYY HH:mm'
                                  );
                                  break;
                                case 'arrival':
                                  //value = formatDate(row['arrival']);
                                  value = dayjs(row['arrival']).format(
                                    'DD/MM/YYYY HH:mm'
                                  );
                                  break;
                                case 'isRoundTrip':
                                  value = row['isRoundTrip'] ? (
                                    <input
                                      type="checkbox"
                                      value={true}
                                      checked
                                      disabled
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      value={false}
                                      unchecked
                                      disabled
                                    />
                                  );
                                  break;
                                case 'isEveningDeparture':
                                  value = row['isEveningDeparture'] ? (
                                    <input
                                      type="checkbox"
                                      value={true}
                                      checked
                                      disabled
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      value={false}
                                      unchecked
                                      disabled
                                    />
                                  );
                                  break;
                                case 'status':
                                  value = row['isEnabled'] ? (
                                    <div className="route-table-status-true">
                                      <CheckIcon
                                        style={{
                                          fontSize: '0.875rem',
                                          color: '#12B76A',
                                        }}
                                      />
                                      {t('route-list.active')}
                                    </div>
                                  ) : (
                                    <div className="route-table-status-false">
                                      <ClearIcon
                                        style={{
                                          fontSize: '0.875rem',
                                          color: '#F04438',
                                        }}
                                      />
                                      {t('route-list.inactive')}
                                    </div>
                                  );
                                  break;
                                case 'price':
                                  value = row['routePrice'];
                                  break;
                                // case 'more-details':
                                //   value = (
                                //     <InfoIcon
                                //       onClick={() =>
                                //         onMoreDetails(row.routeId, 0, row.id)
                                //       }
                                //     />
                                //   )
                                //   break
                                default:
                                  value = row[column.id];
                              }
                              return (
                                <TableCell
                           
                                  key={columnIndex}
                                  align={column.align}
                                  // style={{
                                  //   backgroundColor: !row.isEnabled
                                  //     ? 'rgba(125,25,32,0.15)'
                                  //     : 'inherit',
                                  // }}
                                >
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>

                          {/* Collapsible row */}
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={columns.length + 1}
                            >
                              <Collapse
                                in={expandedRow === rowIndex}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      {extendColumns.map((column, index) => (
                                        <TableCell
                                          key={index}
                                          align={column.align}
                                          style={{ minWidth: column.minWidth }}
                                        >
                                          <strong>{column.label}</strong>
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                    >
                                      {extendColumns.map((column, index) => {
                                        let value = null;
                                        // Determine value for each extendColumn
                                        switch (column.id) {
                                          case 'creatorName':
                                            value = row.creatorName;
                                            break;
                                          case 'reservationsCount':
                                            value = row.reservationsCount;
                                            break;
                                          case 'isRoundTrip':
                                            value = row.isRoundTrip
                                              ? 'With return ticket'
                                              : 'Without return ticket';
                                            break;
                                          /*case 'isActive':
                                            value = row.isActive
                                              ? 'Active'
                                              : 'Inactive';
                                            break;*/
                                          case 'isAvailable':
                                            value = row.isAvailable
                                              ? t('main.enabled') // 'Available'
                                              : t('main.disabled'); //'Unavailable';
                                            break;
                                          case 'edit':
                                            value = (
                                              <IconButton
                                                aria-label="edit"
                                                size="small"
                                                onClick={() =>
                                                  handleEditOpen(row)
                                                }
                                              >
                                                <EditIcon />
                                              </IconButton>
                                            );
                                            break;
                                          case 'enable-disable':
                                            value = row.isEnabled ? (
                                              <Tooltip
                                                title={t('main.disable')}
                                              >
                                                <IconButton
                                                  aria-label="disable"
                                                  size="small"
                                                  onClick={() =>
                                                    handleDisableOpen(
                                                      'disable',
                                                      row.id
                                                    )
                                                  }
                                                >
                                                  <NoLuggageIcon
                                                    style={{ color: 'red' }}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            ) : (
                                              <Tooltip title={t('main.enable')}>
                                                <IconButton
                                                  aria-label="enable"
                                                  size="small"
                                                  onClick={() =>
                                                    handleDisableOpen(
                                                      'enable',
                                                      row.id
                                                    )
                                                  }
                                                >
                                                  <NoLuggageIcon
                                                    style={{ color: 'blue' }}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            );
                                            break;
                                          default:
                                            value = '';
                                        }
                                        return (
                                          <TableCell
                                            key={index}
                                            align={column.align}
                                          >
                                            {value}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {data.bookings.length == 0 && (
                  <h2 style={{ textAlign: 'center' }}>
                    {t('booking.noRides')}
                  </h2>
                )}
                {data.bookings.length !== 0 && (
                  <UsePagination
                    page={page}
                    totalPages={data.totalPages}
                    onPageChange={handleChangePage}
                    reset={resetState}
                  ></UsePagination>
                )}
              </Card>
              {/* {data.bookings.length !== 0 && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={data.totalItems}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )} */}

              {/* <div className="buttons-container">
              <Button
                onClick={() => handleOnPdfDownload()}
                className="filters-button"
              >
                <PictureAsPdfIcon /> &nbsp; {t('booking-list.pdf')}
              </Button>
              <Button
                onClick={() => handleOnExcelDownload()}
                className="filters-button"
              >
                <DownloadIcon /> &nbsp; {t('booking-list.excel')}
              </Button>
            </div> */}
            </>
          </>
        )}
      </div>
    </div>
  );
};
export default ShowAllBookingsComponent;
