import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const ResetForgottenPasswordSuccess = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    if (!state?.message) {
        // Redirect to the home page if accessed directly without state
        navigate('/', { replace: true });
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
            <div style={{ fontSize: '64px', color: 'green' }}>✔</div>
            <p style={{ fontSize: '24px', textAlign: 'center', color: 'green' }}>{state.message}</p>
        </div>
    );
}

export default ResetForgottenPasswordSuccess