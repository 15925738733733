import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  useLazyGetAllDriversQuery
} from '../../features/users/UsersApiSlice';
import DriverAutoComplete from './DriverAutoComplete';
import AssignedDrivers from './AssignedDrivers';
import WhiteButton from '../Button/WhiteButton';
import FilledButton from '../Button/FilledButton';
import { useTranslation } from 'react-i18next';
import {
  useLazyIsDriverAssignedForDateQuery,
  useManageDriversMutation,
} from '../../features/rides/rides';
import { useSnackbar } from '../SneckBarProvider';
import ComfirmationDialog from '../Dialog/ConfirmationDialog';
import WarningDialog from '../Dialog/WarningDialog';
import moment from 'moment';

const ManageDrivers = forwardRef((props, ref) => {
  // export const ManageDrivers=forwardRef((props,ref))=> {
  const { rideId, drivers, onClose, departure } = props;
  const [openComf, setOpenComf] = useState(false);
  const showSnacbar = useSnackbar();
  const { t } = useTranslation();
  const [getAllDrivers, { data: users, isLoading, isSuccess, isError, error }] =
    useLazyGetAllDriversQuery();
  const [isDriverAssignedForDate, { data: isDriverAssigned }] =
    useLazyIsDriverAssignedForDateQuery();

  const [manageDrivers] = useManageDriversMutation();

  const [assignedDrivers, setAssignedDrivers] = useState(drivers);
  const [driversAssgnedToAnotherRides, setDriversAssgnedToAnotherRides] =
    useState([]);

  useEffect(() => {
    getAllDrivers();
  }, []);

  const handleDriverChange = (newDriver) => {
    if (
      newDriver != null &&
      !assignedDrivers.some((driver) => driver?.id === newDriver?.id)
    ) {
      const updatedDriver = { ...newDriver };
      if (!drivers.some((driver) => driver?.id === newDriver?.id)) {
        updatedDriver.type = 'new';
        console.log('new');
        isDriverAssignedForDate({
          departure: departure,
          driverId: newDriver.id,
        });
      }
      setAssignedDrivers((prevDrivers) => [...prevDrivers, updatedDriver]);
    }
  };
  const handleClearAll = () => {
    setAssignedDrivers(drivers);
  };
  const handleOpenConfDialog = () => {
    setOpenComf(true);
  };

  const onUnassingeDriver = (id) => {
    setAssignedDrivers((prevDrivers) =>
      prevDrivers.filter((driver) => driver.id !== id)
    );
    setDriversAssgnedToAnotherRides((prevDrivers) =>
      prevDrivers.filter((driver) => driver.driverId !== id)
    );
  };

  const checkIfThereIsUnsavedChanges = () => {
    const areArraysEqual =
      assignedDrivers.length === drivers.length &&
      assignedDrivers.every((item1) =>
        drivers.some((item2) => item1.id === item2.id)
      ) &&
      drivers.every((item2) =>
        assignedDrivers.some((item1) => item1.id === item2.id)
      );
    // unsavedChanges(allIdsMatch);
    return areArraysEqual;
  };
  const removeKeyFromAll = (keyToRemove) => {
    setAssignedDrivers(
      assignedDrivers.map((driver) => {
        const { [keyToRemove]: _, ...rest } = driver; // Destructure to exclude the key
        return rest;
      })
    );
  };
  const onManageDriversClick = async () => {
    try {
      console.log(assignedDrivers);
      const driverIds = assignedDrivers.map((driver) => driver.id);
      console.log(driverIds);
      const response = await manageDrivers({
        driversIds: driverIds,
        rideId: rideId,
      }).unwrap();
      showSnacbar('Successfully selected drivers.', 'success');
      removeKeyFromAll('type');
      onClose();
    } catch (error) {
      showSnacbar(error?.data?.Message, 'error');
    }
  };

  const handleCloseComfDialog = () => {
    setOpenComf(false);
  };

  useImperativeHandle(ref, () => ({
    check: checkIfThereIsUnsavedChanges,
  }));

  const findDriver = (driverId) => {
    console.log(driverId)
    console.log(drivers)
    const driver = users.find((driver) => driver.id === driverId);
    console.log(driver)
    return driver.name;
  };

  const generateMessage = () => {
    var message = '';
    driversAssgnedToAnotherRides.forEach((driver) => {
      message += `${t("generateMessage.driver")} <b>${findDriver(driver.driverId)}</b>, ${t("generateMessage.alreadyHasScheduledRides")} <b>${moment(driver.driverVehicleAssignment[0].date).format('DD-MM-YYYY')}</b>.<br />`;
      // message+="Voznje: <br />"
      driver.driverVehicleAssignment.forEach((assignedDriverForDate) => {
        message += `${t("generateMessage.driverHasAScheduledRide")} <b>${assignedDriverForDate.route}</b>.<br />`;
      });
      message += '<br />';
    });
    console.log(message);
    return message;
  };

  useEffect(() => {
    if (isDriverAssigned?.driverVehicleAssignment.length > 0) {
      showSnacbar(
        `driverAssignedRide`,
        'warning'
      );
      setDriversAssgnedToAnotherRides((prevDrivers) => [
        ...prevDrivers,
        isDriverAssigned,
      ]);
    }
  }, [isDriverAssigned]);

  useEffect(() => {
    console.log(driversAssgnedToAnotherRides);
    generateMessage();
  }, [driversAssgnedToAnotherRides]);

  return (
    <>
      <div className="manage-drivers-main-container">
        <DriverAutoComplete
          onDriverChange={handleDriverChange}
          drivers={users}
          assignedDrivers={assignedDrivers}
        />
        <AssignedDrivers
          onUnassingeDriver={onUnassingeDriver}
          assignedDrivers={assignedDrivers}
        />
        <div className="predefined-times-buutons-cotainer">
          <WhiteButton isValid={true} onClick={handleClearAll}>
            {t('main.cancel')}
          </WhiteButton>

          <FilledButton
            onClick={handleOpenConfDialog}
            isValid={!checkIfThereIsUnsavedChanges()}
          >
            {t('main.save')}
          </FilledButton>
        </div>
      </div>
      {openComf && (
        <>
          {isDriverAssigned?.driverVehicleAssignment.length > 0 ? (
            <WarningDialog
              open={openComf}
              onClose={handleCloseComfDialog}
              onNo={handleCloseComfDialog}
              onYes={onManageDriversClick}
              dialogContent={generateMessage()}
            />
          ) : (
            <ComfirmationDialog
              open={openComf}
              onClose={handleCloseComfDialog}
              onNo={handleCloseComfDialog}
              onYes={onManageDriversClick}
              dialogContent={'chooseDrivers'}
            />
          )}
        </>
      )}
    </>
  );
});
export default ManageDrivers;
