export const sideLinks = [
    {
      key: 'PaymentMethods',
      to: '/PaymentMethods',
      title: 'appbar.paymentMethod',
      roles: ['Admin'],
    },
    {
      key: 'Subagents',
      to: '/Subagents',
      title: 'appbar.subagent',
      roles: ['Admin'],
    },
    {
      key: 'CustomLocations',
      to: '/CustomLocations',
      title: 'appbar.customLocations',
      roles: ['Admin'],
    },
  ];