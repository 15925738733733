import { useLocation, Navigate, Outlet } from "react-router-dom";

import React from 'react'

const ReguireAuth = ({ allowedRoles }) => {
  const token = localStorage.getItem('token'); // useSelector(selectCurrentToken);
  const roles = localStorage.getItem('roles'); //useSelector(selectCurrentRole);
  const location = useLocation();

  // console.log("Token from require auth(from slice): " + token)
  // console.log("Role from require auth(from slice): " + roles )
  // console.log(  allowedRoles.includes(roles));

  return (
    roles && allowedRoles.includes(roles)
      ? <Outlet />
      : token
        ? <Navigate to='/404' state={{from:location}} replace />
        : <Navigate to='/' state={{from:location}} replace />

  )
}

export default ReguireAuth