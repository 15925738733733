export const handleCreateDownloadLink = (documentBlob, flag, name) => {
    if (documentBlob.size === 0) return;
    const url = window.URL.createObjectURL(documentBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
        'download',
        `${name}.` + (flag === 'excel' ? 'xlsx' : 'pdf')
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};