import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import {
//   Checkbox,
//   Grid,
//   TextField,
//   FormControlLabel,
//   Paper,
//   Button,
//   IconButton,
//   InputAdornment,
//   Box
// } from "@mui/material"

import {
  Container,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  IconButton,
  InputAdornment,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useLoginMutation } from '../../features/auth/authApiSlice';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../features/auth/authSlice';
import './LoginForm.css';
import CustomButton from '../Button/Button';
import LoginIcon from '@mui/icons-material/Login';
import { textFieldStyles } from '../../customStyles/customStyles';
import { useSnackbar } from '../SneckBarProvider';
import PasswordTextField from '../CustomTextFields/PasswordTextField';
import { useTranslation } from 'react-i18next';
import { addConnection } from '../../services/SignalRService';
import { useSignalRConection } from '../../store/context/SignalRContext';
import PageLoader from '../PageLoader/PageLoader';

const LoginForm = () => {
  const connection = useSignalRConection();
  const showSnackbar = useSnackbar();
  const {connect}=useSignalRConection();
  const [checked, setChecked] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState({ message: '', isError: false });
  const [passwordError, setPasswordError] = useState({
    message: '',
    isError: false,
  });
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { t, i18n } = useTranslation();
  const emailRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  const [languageState, setLanguageState] = React.useState('srpski');

  const [login, { isLoading }] = useLoginMutation();

  const dispatch = useDispatch();

  const handleChange = async (event) => {
    setChecked(event.target.checked);
  };

  const changeEmail = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const value = e.target.value;

    setEmail(e.target.value);
    if (value.trim() == '')
      setEmailError({ message:t('login.emailEmpty'), isError: true });
    else setEmailError({ message: '', isError: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  const changePassword = (e) => {
    console.log(e.target.value);
    setPassword(e.target.value);
    const value = e.target.value;
    if (value.trim() == '')
      setPasswordError({ message: 'login.emailEmpty', isError: true });
    else setPasswordError({ message: '', isError: false });
  };
  useEffect(() => {
    // emailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (passwordError.isError) return;
      const userData = await login({ email, password }).unwrap();
      const { id, roles, token, refreshToken, expires } = userData;
      connect(id);
      dispatch(setCredentials({ id, roles, token }));

      setEmail('');
      setPassword('');

      navigate('CreateBooking');
    } catch (error) {
      showSnackbar(error?.data?.Message, 'error');
    }
  };

  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  const hangleChangeLanguage = () => {
    const currentLanguage = i18n.language;
    console.log(currentLanguage);

    if (languageState === 'srpski') {
      setLanguageState('english');
    } else {
      setLanguageState('srpski');
    }

    const newLanguage = i18n.language === 'en' ? 'rs' : 'en';
    i18n.changeLanguage(newLanguage);
  };


  const content = isLoading ? (
    <PageLoader />
  ) : (
    <div className="container">
      <div className="left">
        <div className="overlay">
          <img src="/assets/stars.png" alt="stars" />
        </div>
        <div className="content-container">
          <div className="content-title">
            <p className="big-title">
             {t('login.passport')}
            </p>
            <p className="small-title">  {t('login.smallTitle')}</p>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="header-language"> <span  onClick={hangleChangeLanguage}>
          {i18n.language === 'rs' ? 'SRB' : 'ENG'}
        </span>
        </div>
        <div className="login-form">
          <img
            src="/assets/terra-logo.jpeg"
            alt="logo"
            width="40"
            height="40"
          />
          <h2>{t('login.loginText')}</h2>
          <form className="form-style" onKeyDown={handleKeyDown}>
            <p>  {t('login.details')}</p>

            <label className="text-label" htmlFor="username">
              {t("login.email")}:
            </label>
            <input
              className={`input-text ${emailError.isError ? '' : ''}`}
              autoComplete="false"
              onChange={(e) => changeEmail(e)}
              value={email}
              placeholder={t('login.enterEmail')}
              type="text"
              id="username"
              name="username"
              required
            />
            {emailError.isError && (
              <div className="helper-text">{t('login.emailEmpty')}</div>
            )}

            <label className="text-label" htmlFor="password">
            {t('login.password')}:
            </label>
            <div className="input-wrapper">
              <input
                className="input-pass"
                onChange={(e) => changePassword(e)}
                value={password}
                placeholder=  {t('login.enterPassword')}
                name="password"
                type={showPassword ? 'text' : 'password'}
              />
              <i className="password-toggle" onClick={handleMouseDownPassword}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </i>
            </div>
            {passwordError.isError && (
              <div className="helper-text">{t('login.passwordEmpty')}</div>
            )}

            <div className="form-row">

            </div>
            <button className="log-button" onClick={handleSubmit} type="submit">
            {t('login.signIn')}
            </button>
          </form>
        </div>
        <footer className="footer-text">
          <p>www.terratravel.rs</p>
        </footer>
      </div>
    </div>
  );
  return content;
};

export default LoginForm;
