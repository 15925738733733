import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const CustomNavLink = ({ className, link, icon, customTitle }) => {
  const { t } = useTranslation();
  const userRole = localStorage.getItem('roles');

  if (link.roles.includes(userRole)) {
    return (
      <NavLink className={className} key={link.key} to={link.to}>
        {customTitle ? (
          customTitle
        ) : (
          <>
            {icon} {t(link.title)}
          </>
        )}
      </NavLink>
    );
  }
  return null;
};
