import * as React from 'react';
import CustomDialog from './CustomDialog';
import './ConfirmationDialog.css';
import { useTranslation } from 'react-i18next';
import CustomButton from '../Button/Button';
import { Typography } from '@mui/material';
import { ImportantDevices } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';

export default function WarningDialogContent(props) {
  const {content,handleCloseClick,handleContinueClcik} = props;
  const { t } = useTranslation();

  return (
    <>
    <div className='warning-content-main-container'>
      <div className='warning-header-container'><WarningIcon sx={{fontSize:"24px",color:"#ffab00"}} />{t("warning.warning")}</div>
        <div className='warning-content-cotainer' dangerouslySetInnerHTML={{ __html: content }} />
        <div className="warrning-dialog-button-cotainer">
          <button className='warning-dialog-blank-button'  onClick={handleCloseClick}>
            {t("warning.cancel")}
          </button>
          <button className='yellow-button'  onClick={handleContinueClcik}>{t("warning.continue")}</button>
        </div>
      </div>
    </>

  );
}
