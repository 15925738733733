import React, { useState } from 'react';
import './ThankYouContent.css';
import {handleCreateDownloadLink} from "../../utils/download-blob/handleCreateDownloadLink";
import {useGenerateReservationQRCodePDFMutation} from "../../features/export/exportApiSlice";
import {useParams} from "react-router-dom";

const ThankYouContent = ({ response }) => {
  let spanContent = 'Hvala što ste potvrdili vožnju.';
  let divClassName="pngContainer"
  const [downloadPdfFile] = useGenerateReservationQRCodePDFMutation();
  const { paramName } = useParams();


  const downloadReservationPDF = async () => {
    const { data: pdfBlob } = await downloadPdfFile({token: paramName});
    handleCreateDownloadLink(pdfBlob, 'pdf', 'Potvrda rezervacije');
  }

  console.log(response)

  if (response.data !== undefined) {
    if (response.data.Message !== 'Reservation is already confirmed!') {
      console.log('dasdasdasdsa');
      if (response.data.Message === 'Token has expired!') {
        spanContent = 'Zakasnili ste! Vaša vožnja je prošla.';
        divClassName="invalidPngContainer"
      } else {
        spanContent = 'Došlo je do greske!';
        divClassName="invalidPngContainer"

      }
    }
  }
  else if(response.error){
    spanContent="Došlo je do greške. Pokušajte kasnije."
    divClassName="invalidPngContainer"
  }

  return (
    <div className="thankYouContentMainContainer">
      <div className={divClassName}></div>
      <div className="thankYou-text-container">
        <span>{spanContent}</span>
        {divClassName == 'pngContainer' && (
            <>
              <span>Vaša rezervacija je sada završena.</span>
              <span>Potvrda rezervacije u PDF formatu je preuzeta na vaš uređaj.</span>
              <span>Ukoliko preuzimanje nije automatski počelo, pritisnite na dugme preuzmi</span>
              <span><button className="button-download" onClick={downloadReservationPDF}> Preuzmi </button></span>
            </>
        )}
      </div>
    </div>
  );
};

export default ThankYouContent;
